import { default as NextLink } from "next/link"
import Block from "@/components/wrappers/block"
import Arrow from "@/common/icons/arrow-new"
import OpenInNew from "@/common/icons/open-in-new"
import styles from "./link.module.css"
import classNames from "classnames"

export default function Link({ block, settings = {} }) {
  const { theme, noLink } = settings

  if (!block?.attrs?.text) {
    return null
  }

  if (!block?.attrs?.link?.url) {
    return null
  }

  const hasInnerWrapper = ["arrow-block"]
  const isExternal =
    block.attrs.link.url.substr(0, 4) === "http" || block.attrs.link.target

  return (
    <LinkElem link={block?.attrs?.link} isExternal={isExternal} noLink={noLink}>
      <Block
        block={block}
        noStyling={true}
        className={classNames(styles.link, theme)}>
        {hasInnerWrapper.includes(theme) && <span>{block?.attrs?.text}</span>}
        {!hasInnerWrapper.includes(theme) && block?.attrs?.text}
        {theme === "arrow-block" && (
          <div className="arrow">
            <Arrow />
          </div>
        )}
        {theme === "text-w-icon" && isExternal && (
          <div className="open-in-new">
            <OpenInNew />
          </div>
        )}
      </Block>
    </LinkElem>
  )
}

export const LinkElem = ({ link, isExternal, children, noLink, className }) => {
  if (noLink) {
    return children
  }

  const rel = link.sponsored ? "sponsored" : link.nofollow ? "nofollow" : ""

  const attrs = {
    href: link.url,
    target: link.target ? "_blank" : "_self",
    rel: rel,
    class: className
  }

  return !isExternal ? (
    <NextLink href={link?.url} prefetch={false} className={className}>
      {children}
    </NextLink>
  ) : (
    <a {...attrs}>{children}</a>
  )
}
